export default {
  above: {
    header:
      'Welcome to the Interacoustics Download Center for Instructions for Use',
    description: `<p>Congratulations on your purchase of Interacoustics equipment.</p>
    <p>Please choose your country and relevant device from the drop-down menu. Then enter your email to download the correct Instructions for Use for your device.</p>`,
  },
  below: {
    header: 'Privacy Policy',
    description: `<p>Please note that your email will only be used in connection with download of Instructions for Use. Please also refer to our Privacy Policy on our general use of email information:</p>
    <ul>
      <li><a href="https://www.interacoustics.com/privacy" target="_blank" rel="noopener noreferrer">English privacy policy</a></li>
      <li><a href="https://www.interacoustics.com/de/privacy" target="_blank" rel="noopener noreferrer">German privacy policy</a></li>
      <li><a href="https://www.interacoustics.com/es/privacy" target="_blank" rel="noopener noreferrer">Spanish privacy policy</a></li>
      <li><a href="https://www.interacoustics.com/fr/privacy-policy" target="_blank" rel="noopener noreferrer">French privacy policy</a></li>
      <li><a href="https://www.interacoustics.com/pl/privacy" target="_blank" rel="noopener noreferrer">Polish privacy policy</a></li>
      <li><a href="https://www.interacoustics.kr/privacy" target="_blank" rel="noopener noreferrer">Korean privacy policy</a></li>
    </ul>
    `,
  },
};
