import { useFormContext, Controller } from 'react-hook-form';
import Select from 'react-select';
import { FormSelectInterface } from 'src/types';
import { FormFieldError } from '../FormFieldError/FormFieldError';
import * as S from './FormSelect.styled';

type Props = {
  input: FormSelectInterface;
};

const configValidation = (validation: FormSelectInterface['validation']) => {
  const { required } = validation;

  return { required: { value: required, message: `This field is required` } };
};

export function FormSelect({ input }: Props) {
  const { disabled, id, label, name, placeholder, options, validation } = input;

  const {
    formState: { errors },
    control,
  } = useFormContext();

  const registerInput = control.register(`${name}` as const, {
    ...configValidation(validation),
  });
  const error = errors[name];

  const parsedOptions = options.map((option) => ({
    value: option,
    label: option,
  }));

  return (
    <S.Wrapper>
      <label htmlFor={id}>{label}</label>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur } }) => {
          return (
            <Select
              theme={(theme) => S.customTheme(theme)}
              styles={S.customSelectStyles}
              {...registerInput}
              inputId={id}
              options={parsedOptions}
              placeholder={placeholder}
              isDisabled={disabled}
              onChange={onChange}
              onBlur={onBlur}
            />
          );
        }}
      />
      {error && <FormFieldError message={error.message} />}
    </S.Wrapper>
  );
}
