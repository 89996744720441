import { InstructionInterface, FormOptionsInterface } from 'src/types';
import { InstructionsStrapiResponse, OptionsStrapiResponse } from './types';

enum HTTPMethod {
  GET = 'GET',
  POST = 'POST',
}

/* eslint-disable class-methods-use-this */
class HttpClient {
  private get<GETResponseInterface>(
    url: string,
    method = HTTPMethod.GET,
    body: BodyInit | null | undefined = undefined,
    headers: HeadersInit | undefined = undefined,
  ): Promise<GETResponseInterface> {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method,
        headers,
        body,
      })
        .then((response) => {
          if (!response.ok) throw Error(response.statusText);
          return response.json();
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public async getInstruction(
    country: string,
    device: string,
  ): Promise<InstructionInterface> {
    const [instruction] = await this.get<InstructionsStrapiResponse>(
      `${process.env.REACT_APP_STRAPI_DLCENTER_ENDPOINT}/instructions?filters[country][$eq]=${country}&filters[device][$eq]=${device}`,
    );
    return instruction;
  }

  public async getOptions(): Promise<FormOptionsInterface> {
    const { options } = await this.get<OptionsStrapiResponse>(
      `${process.env.REACT_APP_STRAPI_DLCENTER_ENDPOINT}/instructions/options`,
    );
    return options;
  }

  public async postDataToPardot(postBody: { [index: string]: string }) {
    const url = `${process.env.REACT_APP_PARDOT_ENDPOINT}`;
    const method = HTTPMethod.POST;
    const body = new URLSearchParams(postBody);
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    };

    return this.get(url, method, body, headers);
  }
}

export const api = new HttpClient();
