import { Button } from 'src/components/atoms';
import { IconDownloadFile } from 'src/components/atoms/icons';
import { InstructionInterface } from 'src/types';
import * as S from './Results.styled';

type Props = {
  handleGoBackBtnClick: () => void;
  handleDownloadBtnClick: (link: string) => void;
  instruction: InstructionInterface;
};

export function Results({
  handleGoBackBtnClick,
  handleDownloadBtnClick,
  instruction,
}: Props) {
  const { device, country, language, link } = instruction;

  return (
    <S.Wrapper>
      <p>
        Here you can download requested file for {device} in {country}:
      </p>
      <div>
        <IconDownloadFile />
        <S.Description>
          <S.Title>{`IFU for ${device} (${language})`}</S.Title>
        </S.Description>
        <S.DownloadButton
          btnTheme="primary"
          label="Download"
          handleClick={() => handleDownloadBtnClick(link)}
        />
      </div>
      <Button
        type="button"
        btnTheme="default"
        handleClick={handleGoBackBtnClick}
        label="Search for another document"
      />
    </S.Wrapper>
  );
}
