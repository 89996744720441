const basicTheme = {
  colors: {
    primary: {
      main: '#7dc4a3',
      light: '#d2eadf',
      lighter: '#f3f7f6',
      dark: '#59a181',
    },
    white: {
      main: '#f5f5f7',
      light: '#fff',
    },
    black: '#1d1d1f',
    grey: {
      main: '#86868b',
      light: 'rgba(238,238,238,.95)',
    },
    error: '#ff4151',
  },
  shadow: {
    main: `-5px -5px 15px rgb(255 255 255 / 90%), 5px 5px 15px rgb(68 151 112 / 30%)`,
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif`,
    baseFontSize: 10,
    fontSize: '1.6rem',
    fontWeight: {
      regular: 400,
      bold: 700,
    },
  },
  spacing: {
    standard: '3rem',
  },
  containerWidth: {
    small: '70rem',
  },
  transition: {
    quick: '0.3s ease',
  },
};

const theme = {
  ...basicTheme,
  formField: {
    color: basicTheme.colors.black,
    backgroundColor: basicTheme.colors.white.light,
    fontSize: basicTheme.typography.fontSize,
    fontFamily: basicTheme.typography.fontFamily,
    lineHeight: 'normal',
    border: 'none',
    borderRadius: basicTheme.shape.borderRadius,
    padding: '1.3rem 1.6rem',
    minWidth: '20rem',

    '&:focus, &:focus-visible, &:active': {
      outline: `${basicTheme.colors.primary.main} solid 0.1rem`,
    },

    '&::placeholder': {
      color: basicTheme.colors.grey.main,
      opacity: 1,
    },
  },
};

export default theme;
