import styled from '@emotion/styled';
import { Theme, StylesConfig } from 'react-select';
import configTheme from 'src/styling/configTheme';

export const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexFlow: 'column nowrap',
  width: '100%',
  marginTop: '1.5rem',

  label: {
    margin: '0 0 0.6rem 0.2rem',
  },
}));

export const customTheme = (theme: Theme) => {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary: configTheme.colors.primary.main,
      primary25: configTheme.colors.grey.light,
      neutral50: configTheme.formField['&::placeholder'].color,
    },
    borderRadius: configTheme.formField.borderRadius,
  };
};

export const customSelectStyles: StylesConfig = {
  control: (base) => ({
    ...base,
    ...configTheme.formField,
    cursor: 'pointer',
  }),

  valueContainer: (base) => ({
    ...base,
    padding: 0,
  }),

  dropdownIndicator: (base) => ({
    ...base,
    padding: 0,
  }),

  indicatorSeparator: (base) => ({
    ...base,
    width: 0,
  }),

  input: (base) => ({
    ...base,
    padding: 0,
    margin: 0,
  }),

  placeholder: (base) => ({
    ...base,
    margin: 0,
  }),

  option: (base) => ({
    ...base,
    cursor: 'pointer',
  }),
};
